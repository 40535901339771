<template>
  <div v-resize="onResize">
    <div class="text-center mt-3 font-weight-bold">
      Cliquer sur l'image pour agrandir
    </div>
    <v-row>
      <v-col cols="3" v-for="(img, i) in images" :key="i">
        <v-lazy
          v-model="isActive"
          :options="{
            threshold: 0.5
          }"
          min-height="200"
          transition="scale-transition"
        >
          <v-img
            :src="img.src"
            max-height="200"
            contain
            class="grey lighten-3"
            @click="alrt(i)"
          ></v-img>
        </v-lazy>
      </v-col>
    </v-row>
    <v-overlay :value="overlay" opacity="0.8">
      <v-btn
        absolute
        dark
        fab
        top
        right
        color="teal lighten-2"
        class="mt-12"
        @click="overlay = !overlay"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-row no-gutters>
        <v-col align-self="center">
          <v-row justify="center">
            <v-btn
              fab
              color="teal lighten-2"
              @click="choice--"
              :disabled="choice === 0"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="10">
          <v-img
            :src="images[choice].src"
            contain
            :height="windowSize.height"
            :width="windowSize.width"
          />
        </v-col>
        <v-col align-self="center">
          <v-row justify="center">
            <v-btn
              color="teal lighten-2"
              fab
              @click="choice++"
              :disabled="choice === images.length - 1"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-overlay>
  </div>
</template>

<script>
import imjson from '@/assets/imagesJson.json'

export default {
  data: () => ({
    isActive: false,
    loaded: true,
    overlay: false,
    choice: 0,
    images: imjson,
    windowSize: {
      height: 0,
      width: 0
    }
  }),
  mounted() {
    this.onResize()
    this.$nextTick(function() {
      this.loaded = false
    })
  },
  methods: {
    alrt: function(key) {
      this.choice = key
      this.overlay = true
    },
    onResize() {
      this.windowSize = { width: window.innerWidth, height: window.innerHeight }
    }
  }
}
</script>
